import React, { Component } from "react"
import { slide as Menu } from "react-burger-menu"

import Logo from "../logo/index"

import "./navbar.css"

export class Navbar extends Component {
  scrollToTarget(event) {
    document
      .querySelector(event.currentTarget.dataset.target)
      .scrollIntoView({ behavior: "smooth", position: "top" })
  }

  render() {
    return (
      <header ref={this.props.topReference}>
        <div className="pure-white-wrapper">
          <div className="content-container">
            <div className="navbar">
              <Logo />
              <ul className="nav-items list-unstyled list-inline hidden-xs">
                <li>
                  <button data-target="#about" onClick={this.scrollToTarget}>
                    About
                  </button>
                </li>
                <li>
                  <button data-target="#features" onClick={this.scrollToTarget}>
                    Features
                  </button>
                </li>
                <li>
                  <button data-target="#contact" onClick={this.scrollToTarget}>
                    Contact
                  </button>
                </li>
              </ul>
              <div className="visible-xs">
                <Menu
                  right
                  styles={{
                    bmBurgerBars: {
                      background: "var(--dark-blue)",
                    },
                  }}
                >
                  <ul className="burger-list list-unstyled hidden-xs">
                    <li>
                      <button
                        data-target="#about"
                        onClick={this.scrollToTarget}
                      >
                        About
                      </button>
                    </li>
                    <li>
                      <button
                        data-target="#products"
                        onClick={this.scrollToTarget}
                      >
                        Products
                      </button>
                    </li>
                    <li>
                      <button
                        data-target="#contact"
                        onClick={this.scrollToTarget}
                      >
                        Contact
                      </button>
                    </li>
                  </ul>
                </Menu>
              </div>
            </div>
          </div>
        </div>
      </header>
    )
  }
}

export default Navbar
