/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import Navbar from "./common/navbar/index"
import Footer from "./common/footer/index"
import "./layout.scss"

const Layout = ({ children }) => {
  const mainContainer = React.createRef()

  return (
    <>
      <Navbar topReference={mainContainer} />
      <main>{children}</main>
      <Footer mainContainer={mainContainer} />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
