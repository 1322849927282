import React from "react"

// Call with 'fill' prop to make logo single colour

const Logo = props => (
  <svg
    height={props.height}
    viewBox="0 0 414 90"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <path d="M0 .171h19.856v19.566H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        d="M49.658 66.154c1.415-.726 2.995-1.2 4.151-2.203 2.171-1.884 4.094-4.078 6.127-6.136 1.678-1.699 2.456-1.637 4.063.089 2.138 2.294 4.343 4.521 6.514 6.783 1.187 1.236 1.221 2.412.065 3.64-2.28 2.42-4.57 4.83-6.878 7.222-1.223 1.268-2.155 1.281-3.505-.04-1.49-1.46-2.894-3.016-4.351-4.513-.595-.61-1.224-1.186-1.852-1.76-3.523-3.226-7.26-2.134-10.17.866-1.72 1.772-3.401 3.584-5.131 5.345-1.36 1.382-2.219 1.403-3.586.015a447.28 447.28 0 01-6.81-7.101c-1.193-1.266-1.149-2.505.088-3.804a523.904 523.904 0 014.945-5.13c3.66-3.74 3.736-7.85.166-11.706-1.68-1.813-3.424-3.561-5.113-5.367-1.254-1.34-1.278-2.493-.054-3.782 2.29-2.411 4.602-4.8 6.93-7.171 1.164-1.184 2.103-1.21 3.28-.023a426.69 426.69 0 017.127 7.38c.99 1.048.897 2.362-.134 3.477-2.065 2.232-4.246 4.361-6.17 6.719-2.343 2.872-2.413 6.134-.063 8.971 1.991 2.404 4.26 4.59 6.589 6.644.92.813 2.34 1.011 3.772 1.585"
        fill={props.fill ? props.fill : props.purple}
      />
      <path
        d="M57.432 54.5c-.794-.526-1.59-.898-2.186-1.475-2.29-2.22-4.518-4.503-6.765-6.766-1.414-1.425-1.412-2.376.026-3.819 2.319-2.326 4.646-4.643 6.975-6.958 1.276-1.27 2.44-1.276 3.755.014 1.864 1.828 3.666 3.72 5.567 5.51 2.102 1.978 4.53 3.283 7.496 2.264 1.283-.44 2.538-1.212 3.558-2.112 2.026-1.79 3.872-3.78 5.807-5.673 1.317-1.287 2.35-1.314 3.62-.074a760.227 760.227 0 017.214 7.126c1.208 1.21 1.24 2.335.058 3.539a351.776 351.776 0 01-7.202 7.154c-1.445 1.388-2.277 1.39-3.751.019-2.122-1.972-4.105-4.096-6.266-6.023-3.279-2.924-6.734-2.864-9.989.133-2.059 1.897-3.986 3.935-6.013 5.867-.5.476-1.16.785-1.904 1.274"
        fill={props.fill ? props.fill : props.red}
      />
      <path
        d="M66.425 22.52c-.477.751-.793 1.48-1.305 2.016a364.858 364.858 0 01-6.72 6.83c-1.565 1.552-2.673 1.494-4.196-.069-2.297-2.357-4.648-4.662-6.912-7.053-1.23-1.3-1.121-2.364.134-3.643 2.27-2.314 4.53-4.64 6.797-6.958 1.468-1.502 2.592-1.532 4.083-.042a452.816 452.816 0 016.927 7.047c.476.498.761 1.182 1.192 1.872M25.49 20.5c-.697-.42-1.396-.69-1.895-1.167a369.764 369.764 0 01-7.06-6.94c-1.515-1.53-1.463-2.597.05-4.081 2.286-2.24 4.54-4.51 6.849-6.726 1.53-1.47 2.477-1.438 3.982.036a1562.26 1562.26 0 016.942 6.853c1.391 1.38 1.43 2.497.064 3.87a432.274 432.274 0 01-7.046 6.937c-.5.48-1.185.774-1.885 1.218"
        fill={props.fill ? props.fill : props.green}
      />
      <g transform="translate(.425 44.5)">
        <path
          d="M19.856 9.95c-.365.635-.59 1.293-1.028 1.743a341.236 341.236 0 01-7.033 7.029c-1.44 1.395-2.537 1.338-3.974-.083a1249.434 1249.434 0 01-6.642-6.616c-1.617-1.625-1.555-2.64.09-4.242 2.272-2.212 4.494-4.476 6.742-6.712C9.165-.077 10.364-.149 11.528.986a473.823 473.823 0 017.542 7.54c.34.346.491.876.786 1.424"
          fill={props.fill ? props.fill : props.green}
          mask="url(#b)"
        />
      </g>
      <path
        d="M49.398 69.5c.735.443 1.44.712 1.936 1.194 2.407 2.336 4.77 4.718 7.126 7.106 1.306 1.325 1.282 2.273-.049 3.615a1080.48 1080.48 0 01-7.108 7.118c-1.316 1.303-2.437 1.284-3.767-.024-2.36-2.32-4.722-4.637-7.059-6.979-1.408-1.411-1.401-2.449.007-3.866 2.294-2.31 4.59-4.618 6.933-6.878.532-.514 1.259-.827 1.98-1.286"
        fill={props.fill ? props.fill : props.green}
      />
      <path
        fill={props.fill ? props.fill : props.darkBlue}
        d="M132.16 50.456l-7.058-8.277V62.5h-11.677v-42h8.02l13.153 15.997L146.661 20.5h7.764v42h-11.678V42.18l-5.903 7.658zM187.273 34.401l-3.951 11.797h8.084l-4.133-11.797zm5.713-13.901l15.439 42.852-10.88 1.148-2.979-9.183h-14.283l-2.857 8.545h-11.001L181.924 20.5h11.062zM239.925 29.333H233.8V40.45h6.25c1.75 0 3.187-.525 4.312-1.575 1.125-1.05 1.688-2.398 1.688-4.046 0-1.81-.532-3.18-1.594-4.107-1.063-.926-2.574-1.39-4.531-1.39zm-17.5-8.833h17.874c5.084 0 9.271 1.195 12.563 3.582 3.291 2.39 4.937 5.972 4.937 10.747 0 2.471-.51 4.644-1.53 6.517a12.837 12.837 0 01-4.22 4.601l9.376 16.553h-13.188l-6.875-13.218H233.8V62.5h-11.375v-42zM305.425 29.333H292.02V62.5h-11.243V29.333h-12.353V20.5h35.95zM335.273 34.401l-3.951 11.797h8.084l-4.133-11.797zm5.713-13.901l15.439 42.852-10.88 1.148-2.979-9.183h-14.283l-2.857 8.545h-11.001L329.924 20.5h11.062zM388.234 29.333H382v12.105h6.36c1.78 0 3.244-.556 4.389-1.667 1.145-1.112 1.717-2.491 1.717-4.138 0-1.894-.563-3.418-1.685-4.571-1.124-1.152-2.64-1.73-4.548-1.73zM382 62.5h-11.576v-42h18.19c3.308 0 6.307.567 9 1.699 2.693 1.133 4.835 2.841 6.424 5.126 1.59 2.285 2.386 5.055 2.386 8.308 0 3.211-.85 5.92-2.545 8.121-1.696 2.204-3.933 3.84-6.71 4.91-2.778 1.072-5.799 1.607-9.063 1.607H382V62.5z"
      />
    </g>
  </svg>
)

Logo.defaultProps = {
  fill: null,
  green: "#b9deda",
  darkBlue: "#35477e",
  red: "#ef8080",
  purple: "#d787b7",
  height: "50",
}

export default Logo
