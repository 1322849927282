import React, { Component } from "react"
import Drift from "react-driftjs"

import Logo from "../logo/index"

import "./footer.css"

export class Footer extends Component {
  scrollToTop(event) {
    this.props.mainContainer.current.scrollIntoView({
      behavior: "smooth",
      position: "top",
    })
  }

  render() {
    return (
      <footer>
        <div className="dark-blue-wrapper relative">
          <div className="footer-buttons">
            <Drift appId="pe9ve265b8fe" />
            <button
              scrolltarget={this.mainContainer}
              onClick={this.scrollToTop.bind(this)}
            >
              <span>&uarr;</span>
            </button>
          </div>
          <div className="content-container">
            <div className="footer">
              <Logo fill="white" />
              <div className="footer-content">
                <div className="columns">
                  <div className="column">
                    <ul className="list-unstyled">
                      <li>
                        <strong>Address</strong>
                      </li>
                      <li>Van Eeghenstraat 98</li>
                      <li>1071 GL Amsterdam</li>
                    </ul>
                  </div>
                  <div className="column">
                    <ul className="list-unstyled">
                      <li>
                        <strong>Contact</strong>
                      </li>
                      <li>
                        <a href="mailto:info@martap-solutions.com">
                          info@martap-solutions.com
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="red-wrapper">
          <div className="footer-cta">
            <a href="https://calendly.com/r-broek">
              <h4>Schedule a call &rarr;</h4>
            </a>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
